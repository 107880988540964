


























import { Component, Vue } from 'vue-property-decorator'
import { WalletType } from '@/js/wallets/types'
import SearchAddress from '@/components/wallet/advanced/SignMessage/SearchAddress.vue'
import { SingletonWallet } from '@/js/wallets/SingletonWallet'
@Component({
    components: { SearchAddress },
})
export default class SignMessage extends Vue {
    sourceAddress = null
    message = ''
    signed = ''
    error = ''

    get wallet(): WalletType {
        return this.$store.state.activeWallet
    }

    async sign() {
        this.error = ''
        try {
            // Convert the message to a hashed buffer
            // let hashMsg = this.msgToHash(this.message);
            if (this.wallet.type === 'singleton') {
                this.signed = await (this.wallet as SingletonWallet).signMessage(this.message)
            } else {
                this.signed = await this.wallet.signMessage(this.message, this.sourceAddress!)
            }
        } catch (e) {
            this.error = e
        }
    }

    clear() {
        this.message = ''
        this.signed = ''
        this.error = ''
    }

    deactivated() {
        this.clear()
    }

    get isHD() {
        return this.wallet.type !== 'singleton'
    }

    get canSubmit(): boolean {
        if (!this.sourceAddress && this.isHD) return false
        if (!this.message) return false

        return true
    }
}
