


















































































































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import { bintools, keyChain } from '@/AVA'
import AvaAsset from '@/js/AvaAsset'
import { AssetsDict } from '@/store/modules/assets/types'
import { AmountOutput, KeyPair as AVMKeyPair } from '@lamina1/lamina1-js/dist/apis/avm'
import MnemonicPhraseModal from '@/components/modals/MnemonicPhraseModal.vue'
import HdDerivationListModal from '@/components/modals/HdDerivationList/HdDerivationListModal.vue'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
import Tooltip from '@/components/misc/Tooltip.vue'

import ExportKeys from '@/components/modals/ExportKeys.vue'
import PrivateKey from '@/components/modals/PrivateKey.vue'
import { WalletNameType, WalletType } from '@/js/wallets/types'

import { SingletonWallet } from '../../../js/wallets/SingletonWallet'
import MnemonicPhrase from '@/js/wallets/MnemonicPhrase'
import XpubModal from '@/components/modals/XpubModal.vue'
import { HdWalletCore } from '@/js/wallets/HdWalletCore'

interface IKeyBalanceDict {
    [key: string]: AvaAsset
}

@Component({
    components: {
        MnemonicPhraseModal,
        HdDerivationListModal,
        Tooltip,
        ExportKeys,
        PrivateKey,
        XpubModal,
    },
})
export default class KeyRow extends Vue {
    @Prop() wallet!: WalletType
    @Prop({ default: false }) is_default?: boolean

    $refs!: {
        export_wallet: ExportKeys
        modal: MnemonicPhraseModal
        modal_hd: HdDerivationListModal
        modal_priv_key: PrivateKey
        modal_xpub: XpubModal
    }

    get isVolatile() {
        return this.$store.state.volatileWallets.includes(this.wallet)
    }

    get walletTitle() {
        return this.wallet.getBaseAddress()
    }

    get assetsDict(): AssetsDict {
        return this.$store.state.Assets.assetsDict
    }

    get balances(): IKeyBalanceDict {
        if (!this.wallet.getUTXOSet()) return {}

        let res: IKeyBalanceDict = {}

        let addrUtxos = this.wallet.getUTXOSet().getAllUTXOs()
        for (var n = 0; n < addrUtxos.length; n++) {
            let utxo = addrUtxos[n]

            // ignore NFTS and mint outputs
            //TODO: support nfts
            let outId = utxo.getOutput().getOutputID()
            if (outId === 11 || outId === 6 || outId === 10) continue

            let utxoOut = utxo.getOutput() as AmountOutput

            let amount = utxoOut.getAmount()
            let assetIdBuff = utxo.getAssetID()
            let assetId = bintools.cb58Encode(assetIdBuff)

            let assetObj: AvaAsset | undefined = this.assetsDict[assetId]

            if (!assetObj) {
                let name = '?'
                let symbol = '?'
                let denomination = 0

                let newAsset = new AvaAsset(assetId, name, symbol, denomination)
                newAsset.addBalance(amount)

                res[assetId] = newAsset
                continue
            }

            let asset = res[assetId]
            if (!asset) {
                let name = assetObj.name
                let symbol = assetObj.symbol
                let denomination = assetObj.denomination

                let newAsset = new AvaAsset(assetId, name, symbol, denomination)
                newAsset.addBalance(amount)

                res[assetId] = newAsset
            } else {
                asset.addBalance(amount)
            }
        }

        return res
    }

    get walletType(): WalletNameType {
        return this.wallet.type
    }

    get isHDWallet() {
        return ['mnemonic', 'ledger'].includes(this.walletType)
    }
    get mnemonicPhrase(): MnemonicPhrase | null {
        if (this.walletType !== 'mnemonic') return null
        let wallet = this.wallet as MnemonicWallet
        return wallet.getMnemonicEncrypted()
    }

    get privateKey(): string | null {
        if (this.walletType !== 'singleton') return null
        let wallet = this.wallet as SingletonWallet
        return wallet.key
    }

    get privateKeyC(): string | null {
        if (this.walletType === 'ledger') return null
        let wallet = this.wallet as SingletonWallet | MnemonicWallet
        return wallet.ethKey
    }

    /**
     * Extended public key of m/44'/9000'/0' used for X and P chain addresses
     */
    get xpubXP() {
        if (this.isHDWallet) {
            return (this.wallet as HdWalletCore).getXpubXP()
        }
        return null
    }

    remove() {
        this.$emit('remove', this.wallet)
    }
    select() {
        this.$emit('select', this.wallet)
    }

    showModal() {
        let modal = this.$refs.modal
        //@ts-ignore
        modal.open()
    }

    showXpub() {
        this.$refs.modal_xpub.open()
    }

    showPastAddresses() {
        let modal = this.$refs.modal_hd
        //@ts-ignore
        modal.open()
    }

    showExportModal() {
        //@ts-ignore
        this.$refs.export_wallet.open()
    }

    showPrivateKeyModal() {
        //@ts-ignore
        this.$refs.modal_priv_key.open()
    }

    showPrivateKeyCModal() {
        //@ts-ignore
        this.$refs.modal_priv_key_c.open()
    }
}
