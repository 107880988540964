




























import { Vue, Component, Model, Watch } from 'vue-property-decorator'
import UtxoSelectModal from '@/components/modals/UtxoSelect/UtxoSelect.vue'
import { AmountOutput, UTXO, UTXOSet } from '@lamina1/lamina1-js/dist/apis/platformvm'
import { WalletType } from '@/js/wallets/types'

import { CurrencyType } from '@/components/misc/CurrencySelect/types'
import { BN } from '@lamina1/lamina1-js'
import { bnToBig } from '@/helpers/helper'
import { UnixNow } from '@lamina1/lamina1-js/dist/utils'
@Component({
    components: {
        UtxoSelectModal,
    },
})
export default class UtxoSelectForm extends Vue {
    customUtxos: UTXO[] = []
    formType = 'all'
    @Model('change', { type: Array }) readonly utxos!: UTXO[]

    @Watch('customUtxos')
    onCustomChange(utxos: UTXO[]) {
        if (this.formType === 'custom') {
            this.$emit('change', utxos)
        }
    }

    onTypeChange(val: string) {
        if (val === 'all') {
            this.selectAll()
        } else {
            this.selectCustom()
        }
    }
    openModal() {
        //@ts-ignore
        this.$refs.modal.open()
    }
    selectCustom() {
        this.$emit('change', this.customUtxos)
    }

    selectAll() {
        this.$emit('change', this.platformUtxos)
    }

    mounted() {
        this.selectAll()
    }

    clear() {
        this.selectAll()
    }

    get platformUtxos(): UTXO[] {
        let wallet: WalletType | null = this.$store.state.activeWallet
        if (!wallet) return []
        const utxos = wallet.getPlatformUTXOSet().getAllUTXOs()
        const now = UnixNow()
        return utxos.filter((utxo) => {
            // Filter out locked and multisig utxos
            const locktime = utxo.getOutput().getLocktime()
            const threshold = utxo.getOutput().getThreshold()
            if (locktime.gt(now)) return false
            if (threshold > 1) return false
            return true
        })
    }

    get selectedBalance(): BN {
        if (this.formType === 'all') {
            return this.platformUtxos.reduce((acc, val: UTXO) => {
                let out = val.getOutput() as AmountOutput
                return acc.add(out.getAmount())
            }, new BN(0))
        } else {
            return this.customUtxos.reduce((acc, val: UTXO) => {
                let out = val.getOutput() as AmountOutput
                return acc.add(out.getAmount())
            }, new BN(0))
        }
    }
    get selectedBalanceText() {
        return bnToBig(this.selectedBalance, 9).toLocaleString()
    }

    @Watch('platformUtxos')
    onPlatformUtxosChange(utxos: UTXO[]) {
        if (this.formType === 'all') {
            this.selectAll()
        }
    }
}
