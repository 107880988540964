





























import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AvaWalletCore } from '../../../js/wallets/types'
import {
    DelegatorPendingRaw,
    DelegatorRaw,
    ValidatorPendingRaw,
    ValidatorRaw,
} from '@/components/misc/ValidatorList/types'
import UserRewardRow from '@/components/wallet/earn/UserRewardRow.vue'
import { bnToBig } from '@/helpers/helper'
import Big from 'big.js'
import { BN } from '@lamina1/lamina1-js'

@Component({
    components: {
        UserRewardRow,
    },
})
export default class UserRewards extends Vue {
    get userAddresses() {
        let wallet: AvaWalletCore = this.$store.state.activeWallet
        if (!wallet) return []

        return wallet.getAllAddressesP()
    }

    get validators(): ValidatorRaw[] {
        let validators: ValidatorRaw[] = this.$store.state.Platform.validators

        return this.cleanList(validators) as ValidatorRaw[]
    }

    get delegators(): DelegatorRaw[] {
        let delegators: DelegatorRaw[] = []
        let validators: ValidatorRaw[] = this.$store.state.Platform.validators

        for (var i = 0; i < validators.length; i++) {
            let v = validators[i]
            if (v.delegators === null) continue
            delegators.push(...v.delegators)
        }

        return this.cleanList(delegators) as DelegatorRaw[]
    }

    get totLength() {
        return this.validators.length + this.delegators.length
    }

    get totalReward() {
        let vals = this.validators.reduce((acc, val: ValidatorRaw) => {
            return acc.add(new BN(val.potentialReward))
        }, new BN(0))

        let dels = this.delegators.reduce((acc, val: DelegatorRaw) => {
            return acc.add(new BN(val.potentialReward))
        }, new BN(0))

        return vals.add(dels)
    }

    get totalRewardBig(): Big {
        return bnToBig(this.totalReward, 9)
    }

    cleanList(list: ValidatorRaw[] | DelegatorRaw[]) {
        let res = list.filter((val) => {
            let rewardAddrs = val.rewardOwner.addresses
            let filtered = rewardAddrs.filter((addr) => {
                return this.userAddresses.includes(addr)
            })
            return filtered.length > 0
        })

        res.sort((a, b) => {
            let startA = parseInt(a.startTime)
            let startB = parseInt(b.startTime)
            return startA - startB
        })
        return res
    }
}
